import { graphql, Link, useStaticQuery } from 'gatsby';
import React from 'react';

import Layout from '../components/layout';
import Menu from '../components/menu';
import SEO from '../components/seo';
import * as headerStyles from '../styles/common.module.css';

/*
Note:
* Any update to strapi or postgres db needs gatsby restart
* For localhost:8000/__graphql use following:
{allStrapiPost {
      edges {
        node {
          id
          title
          body
          className
        }
      }
    }
}
*/
function formatData( data ) {
  let tempDictionary = {};
  let outputArray = [];
  let title = 'privacy-policy-para';// headerStyles.head

  data.privacyParagraphs.edges.forEach( ( edge, index ) => {
    // className: edge.node.className,
    if ( edge.node.title.startsWith( title ) ) tempDictionary[edge.node.title] = { className: edge.node.className, body: edge.node.body };
  } );

  for ( let counter = 0; counter < Object.keys( tempDictionary ).length; counter += 1 ) {
    let key = `${title}${counter}`;
    outputArray.push( { className: tempDictionary[key].className, body: tempDictionary[key].body } );
  }

  return outputArray;
}

function PrivacyPage(){
  const data = useStaticQuery( graphql`
  query {
    allStrapiUser {
      edges {
        node {
          email
          username
          role {
            name
          }
        }
      }
    }
    privacyParagraphs:allStrapiPost(filter: {page_name: {eq: "privacy"}}) {
      edges {
        node {
          id
          title
          body
          className,
          category
        }
      }
    }
  }
` );

  return(
    <Layout header={'menu'}>
      <SEO
        title="Privacy Policy"
        description="Page three description"
      />

      {/* <Menu /> */}

      <div className="wrapper">
        <div className="termsconditionwrapper">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <header>
                  <nav>
                    <ul className="nav justify-content-center page-tab-head" >
                      <li className="nav-item">
                        <Link className={headerStyles.navItem} activeClassName={headerStyles.activeNavItem} to="/termsandconditions">Terms</Link>
                      </li>

                      <li className="nav-item">
                        <Link className={headerStyles.navItem} activeClassName={headerStyles.activeNavItem} to="/privacy"><u>Privacy Policy</u></Link>
                      </li>

                      <li className="nav-item">
                        <Link className={headerStyles.navItem} activeClassName={headerStyles.activeNavItem} to="/access">Accessibility</Link>
                      </li>
                    </ul>
                  </nav>
                </header>
              </div>
            </div>

            <div className="row mgt-50">
              <div className="col-12">
                {formatData( data ).map( ( d, i ) => {
                  return <p key={i} className={d.className}>{d.body}</p>;
                } )}
              </div>

              <div className="col-12">
                <Link to="/">Go back to the homepage</Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}
export default PrivacyPage;
